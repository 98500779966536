@use 'src/styles/variables/layout';

.text-and-image-paragraph {
    display: grid;
    grid-gap: 1.6rem;
    grid-template-columns: auto;
    margin: 0 0 30px 0;

    @media screen and (min-width: layout.$for-tablet-portrait-up) {
        grid-template-columns: 1fr 1fr;
    }

    >hpo-placeholder {
        grid-column: span 2;
    }

    &__link {
        width: 100%;
        margin: 0 0 25px 0;
        display: block;

        grid-column: 1;

        @media screen and (min-width: layout.$for-tablet-portrait-up) {
            grid-column: 1;
            margin: 0 0px 0 0;

            &--reversed {
                grid-column: 2;
                margin: 0 0 0 0px;
                text-align: center;
            }
        }
    }

    &__image {
        cursor: pointer;
        max-width: 100%;
        object-fit: contain;
        grid-column: 1;
        page-break-inside: avoid;

        @media screen and (min-width: layout.$for-tablet-portrait-up) {
            position: relative;
            top: 5px;
        }

        @media print {
            max-width: 80%;
        }
    }

    figure {
        margin: 0;
        display: inline-block;
    }

    figcaption {
        text-align: center;

        @media print {
            display: none;
        }
    }

    &__content {
        // Content styles in src/styles/paragraphs/_text-and-image.scss

        grid-column: 1;
        grid-row: 2;

        @media screen and (min-width: layout.$for-tablet-portrait-up) {
            grid-column: 1;
            grid-row: 1;

            &.reversed {
                grid-column: 2;
            }
        }
    }
}
.checklist-paragraph {
    background-color: #fbf0f0;
    border-radius: 5px;
    border: 1px solid #ffc5c5;
    box-shadow: 0 1px 2px rgba(7, 7, 7, 0.2);
    margin: 0 0 30px 0;
    padding: 30px 20px 60px;
    position: relative;
    page-break-inside: avoid;

    &__title {
        color: #f83c3b;
        font-family: "Helvetica Neue", sans-serif;
        font-size: 20px;
        line-height: 1;
        margin: 0 0 25px 0;
    }

    // Content styles in src/styles/paragraphs/_checklist.scss
}
import { Component, OnInit, EventEmitter, OnDestroy, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FullScreenDialogComponent, FullScreenDialogService } from '@headpower/components';
import { LayoutBreakpointService } from '@headpower/layout';
import { Subject, takeUntil } from 'rxjs';
import { GroupPriority, PortalProduct } from 'src/app/modules/core/services/portal/portal-product';
import { PortalService } from 'src/app/modules/core/services/portal/portal.service';
import { TagGroup } from 'src/app/modules/shared/models/tag-group';
import { Tag } from '../../../shared/models/tag.model';
import { loadKeyword, loadProductId, loadSelectedTags, saveKeyword, saveProductId, saveSelectedTags } from '../../shared';
import { ProductDialogComponent, ProductDialogDataInterface } from '../product-dialog/product-dialog.component';
import { TagDialogComponent, TagDialogDataInterface } from '../tag-dialog/tag-dialog.component';

@Component({
    selector: 'app-catalog-headpower',
    templateUrl: './catalog-headpower.component.html',
    styleUrls: ['./catalog-headpower.component.scss']
})
export class CatalogHeadpowerComponent implements OnInit, OnDestroy, AfterContentChecked {

    products: PortalProduct[] = [];
    mobile = false;

    // state
    productName: string;
    selectedProductId = 0;
    keyword = '';
    resultCount = 0;

    // tags of all sort
    tags: Tag[] = [];
    selectedTags: Tag[] = [];
    selectableTags: Tag[] = [];
    tagGroups: TagGroup[] = [];

    // dialogRefs
    tagDialogRef: MatDialogRef<FullScreenDialogComponent>;
    productDialogRef: MatDialogRef<FullScreenDialogComponent>;

    // other things
    public PREFIX = 'headpower';
    public localGroupPriority = GroupPriority;
    public hasCompanysInstructions: boolean = false;

    private destroy$: Subject<void> = new Subject();
    public firstLoadingDone: boolean = false;

    public clickedTag;

    constructor(
        private route: ActivatedRoute,
        private fsDialogService: FullScreenDialogService,
        private portalService: PortalService,
        private layoutBreakpointService: LayoutBreakpointService,
        private router: Router,
        private changeDetector: ChangeDetectorRef) {
        this.route.queryParams.subscribe(params => {
            if (params?.id) {
                this.clickedTag = [{
                    id: params['id'],
                    attributes: { name: params['name'] }
                }]
            }
        });
    }

    ngOnInit() {
        this.restoreState();
        this.fetchProducts();

        this.layoutBreakpointService.observer$
            .pipe(takeUntil(this.destroy$))
            .subscribe(result => this.mobile = result.handset);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private fetchProducts() {
        this.portalService.getApplications().subscribe((res) => {
            this.products = res;
            this.hasCompanysInstructions = !!res.find((p) => p.GroupPriority === this.localGroupPriority.SUPPLIER)
            this.parseProductName();
        });
    }

    private parseProductName() {
        const product = this.products.find(
            (p) => p.Access_UID === this.selectedProductId,
        );
        if (product) {
            this.productName = product.AccessName;
        }
    }

    private restoreState() {
        this.keyword = loadKeyword(this.PREFIX);
        this.selectedTags = this.clickedTag ? this.clickedTag : loadSelectedTags(this.PREFIX);
        this.selectedProductId = loadProductId(this.PREFIX);

        // override product id if coming from params and we don't have another product selected
        this.route.queryParams.subscribe((routeParams) => {
            const productIdFromRoute = parseInt(routeParams.productId, 10);
            if (productIdFromRoute && !this.selectedProductId) {
                this.selectedProductId = productIdFromRoute;
            } else if (!productIdFromRoute && this.selectedProductId) {
                // make sure the product id exists in the url
                this.router.navigate([], {
                    queryParams: { productId: this.selectedProductId },
                });
            }
        });
    }

    public selectProduct = (product: PortalProduct | undefined) => {
        if (product) {
            const { AccessName, Access_UID } = product;
            this.productName = AccessName;
            this.selectedProductId = Access_UID;
            saveProductId(this.PREFIX, Access_UID);
        } else {
            this.productName = '';
            this.selectedProductId = 0;
            saveProductId(this.PREFIX, 0);
        }

        // reset tag selection, because different products might have different tags
        // TODO: clear only those that are not present :P
        this.selectedTags = [];
        saveSelectedTags(this.PREFIX, []);

        // update product dialog
        this.updateProductDialog();
    };

    public onKeywordChange(keyword: string): void {
        this.keyword = keyword;
        saveKeyword(this.PREFIX, keyword);
    }

    public updateSelectableTags(tags: Tag[]) {
        this.selectableTags = tags;

        // update tag dialog as well
        this.updateTagDialog();
    }

    public updateSelectedTags(selectedTags: Tag[]) {
        this.selectedTags = selectedTags;
        saveSelectedTags(this.PREFIX, selectedTags);

        // update tag dialog as well
        this.updateTagDialog();
    }

    public updateTags(tags: Tag[]) {
        this.tags = tags;
    }

    public updateTagGroups(tagGroups: TagGroup[]) {
        this.tagGroups = tagGroups;
    }

    public updateResultCount(resultCount: number) {
        this.resultCount = resultCount;
        this.firstLoadingDone = true;
        this.updateProductDialog();
    }

    private updateProductDialog() {
        if (this.productDialogRef && this.productDialogRef.componentInstance) {
            const dialogData = (this.productDialogRef.componentInstance as any)
                .dialogData;
            dialogData.selectedProductId = this.selectedProductId;
        }
    }

    private updateTagDialog() {
        if (this.tagDialogRef && this.tagDialogRef.componentInstance) {
            const dialogData = (this.tagDialogRef.componentInstance as any)
                .dialogData;
            dialogData.selectedTags = this.selectedTags;
            dialogData.selectableTags = this.selectableTags;
        }
    }

    public mobileProducts() {
        const originalSlectedProductId = this.selectedProductId;

        const emitProductSelect = new EventEmitter<PortalProduct>();
        emitProductSelect
            .pipe(takeUntil(this.destroy$))
            .subscribe(product => {
                this.selectProduct(product);
            });

        this.productDialogRef = this.fsDialogService.open(ProductDialogComponent, {
            autoFocus: false,
            data: {
                dialogTitle: 'default.companysInstructions',
                actionLabel: 'default.filter',
                products: [...this.products],
                selectedProductId: this.selectedProductId,
                GroupPriority: GroupPriority.SUPPLIER,
                emitProductSelect,
            } as ProductDialogDataInterface,
        });
        this.productDialogRef.afterClosed().subscribe((confirmed = false) => {
            if (!confirmed) {
                // revert to original state
                const originalProduct = this.products.find(
                    (p) => p.Access_UID === originalSlectedProductId,
                );
                this.selectProduct(originalProduct);
            }
        });
    }

    public mobileTags() {
        const originalSelectedTags = [...this.selectedTags];

        const emitSelectedTags = new EventEmitter<Tag[]>();
        emitSelectedTags
            .pipe(takeUntil(this.destroy$))
            .subscribe(selectedTags => {
                this.updateSelectedTags(selectedTags);
            });

        this.tagDialogRef = this.fsDialogService.open(TagDialogComponent, {
            autoFocus: false,
            data: {
                dialogTitle: 'default.filterTags',
                actionLabel: 'default.filter',
                tags: [...this.tags],
                tagGroups: [...this.tagGroups],
                selectedTags: [...this.selectedTags],
                selectableTags: [...this.selectableTags],
                emitSelectedTags
            } as TagDialogDataInterface,
        });
        this.tagDialogRef.afterClosed().subscribe((confirmed = false) => {
            if (!confirmed) {
                // revert to original state
                this.updateSelectedTags(originalSelectedTags);
            }
        });
    }

    ngAfterContentChecked(): void {
        this.changeDetector.detectChanges();
    }

    public removeTag(tag, type) {
        if (type === 'tag') {
            const tags = this.selectedTags.filter(t => t.id !== tag.id)
            this.updateSelectedTags(tags);
        } else if (type === 'product') {
            this.selectProduct(undefined);
            this.router.navigate([], {
                queryParams: { productId: undefined },
            });
        }

    }
}
